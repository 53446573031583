import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/react"

const Button = props => (
  <Link
    to={props.to}
    css={css`
      text-decoration: none;
      font-size: 0.95em;
      color: #d69c31;
      font-weight: 400;
      :hover,
      :focus {
        opacity: 0.7;
        color: #171717;
      }
    `}
  >
    + {props.text}
  </Link>
)

export default Button

import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Button from "../components/button"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { allMarkdownRemark } = data // data.markdownRemark holds our post data

  // filter current page fron other page array

  return (
    <Layout pathEn="/en/">
      <SEO
        title="Actualités - Dr Philippe Roure"
        description="Découvez les derniers articles ainsi que l'actualité du Dr Roure, chirurgien orthopédiste à Paris spécialiste du membre supérieur (main, coude, poignet, épaule)."
      />
      <Helmet>
        <script type="application/ld+json">{`
  {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Actualités",
      "item": "https://www.docteurphilipperoure.com/actus/"
    }]
}
`}</script>
      </Helmet>
      <WrapperText>
        <H1>Actualités</H1>
        <hr />
        {allMarkdownRemark.edges.map(({ node }) => {
          const { title, excerpt, date, image, imageAlt } = node.frontmatter
          const { slug } = node.fields
          return (
            <Article>
              <Col1>
                <Link to={slug}>
                  <Img
                    fluid={image.childImageSharp.fluid}
                    css={{ maxWidth: `400px` }}
                    alt={imageAlt}
                  />
                </Link>
              </Col1>

              <Col2>
                <Link
                  to={slug}
                  css={css`
                    text-decoration: none;
                  `}
                >
                  <H2>{title}</H2>
                </Link>
                <Date>{date}</Date>
                <P>{excerpt}</P>
                <div
                  style={{
                    textAlign: `right`,
                    marginRight: `4em`,
                    marginTop: `5px`,
                  }}
                >
                  <Button to={slug} text="Lire la suite" />
                </div>
              </Col2>
            </Article>
          )
        })}
      </WrapperText>
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 2.3em;
  margin-top: 1em;
  @media (max-width: 678px) {
    font-size: 2.2em;
    line-height: 1.1em;
    padding-top: 1em;
  }
`

const WrapperText = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 0;
  }
`

const Article = styled.article`
translateY(0px);
display: flex;
padding: 3em;
margin-bottom: 1em;
@media (max-width: 1024px) {
  padding: 40px 0 0;
}
@media (max-width: 678px) {
  display: block;
  margin-top: 25px;
  padding: 0;
}
`

const Col1 = styled.div`
  flex: 33%;
  padding-right: 12px;
  .gatsby-image-wrapper img {
    transition: all 0.7s !important;
    transform: scale(1) !important;
  }
  &:hover {
    transition: all 0.2s;
    .gatsby-image-wrapper img {
      transition: all 0.7s !important;
      transform: scale(1.05) !important;
    }
    @media (max-width: 678px) {
      &:hover {
        box-shadow: none;
        .gatsby-image-wrapper img {
          transform: scale(1) !important;
        }
      }
  }
`

const Col2 = styled.div`
  flex: 66%;
  padding-left: 12px;
`

const H2 = styled.h2`
  font-size: 1.6em;
  line-height: 1.35em;
  margin: 0 0 0.2em;
  margin-block-start: 0;
  @media (max-width: 678px) {
    margin-top: 12px;
    font-size: 1.45em;
    line-height: 1.3em;
  }
`

const Date = styled.div`
  color: #4f5767;
  font-weight: 600;
  font-size: 0.77em;
  margin-left: 2px;
  letter-spacing: 0.03em;
  margin-bottom: 0.2em;
`

const P = styled.p`
  margin: 0 0 0 2px;
`

export const pageQuery = graphql`
  query Actualites {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "actus" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 813) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`
